.home {
  display: flex;
  flex-direction: row;
  height: calc(100% - 71px);
  overflow: hidden;
  position: fixed;
  width: 100%;

}
.first-col {
  width: 220px;
  overflow-y: auto;
  height: 100%;
  background-color: #f5f5f5;
}
.second-col {
  width: 300px;
  overflow-y: auto;
  height: 100%;
}
.main-col {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
  height: 100%;
}

.main-content {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.main-wrapper {
  flex: 1;
  display: flex;
}

a {
  text-decoration: none;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-next, .slick-prev {
  z-index: 1;
  width: 50px;
  height: 50px;
}

.slick-next:before, .slick-prev:before {
  font-size: 50px;
  line-height: 1;
  opacity: .75;
  color: rgba(0,0,0, 0.1);
}

.slick-slider:hover .slick-next:before, .slick-slider:hover .slick-prev:before {
  color: rgba(0,0,0, 0.6);
}
