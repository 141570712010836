.invoice-photos {
  max-width: 500px;
}

.invoice-photo-source {
  width: 100%;
}

.slick-slider {
  margin-top: 5px;
}
